import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import ModalPortfolio from '@solid-ui-blocks/Modal/Block03'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import ContentOne from '@solid-ui-blocks/Content/Block01'
import ContentTwo from '@solid-ui-blocks/Features/Block06'
import Footer from '@solid-ui-blocks/Footer/Block02'
import Team from '@solid-ui-blocks/Teams/Block02'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import Download from '@solid-ui-blocks/CallToAction/Block02'
import ContentMap from '@solid-ui-components/ContentMap'

const PatientForms = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='Patient Forms' indexed={true} />
      {/* Modals */}
      {/* <ModalSimple content={content['advertisement']} /> */}
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='4' />
      <Hero content={content['hero']} reverse />
      {/* <Divider space='4' /> */}
      <Container variant='wide'>
        {/* <ContentOne content={content['content-one']} /> */}
        <Download content={content['download-1']} />
        <Divider space='2' />
        <Download content={content['download-2']} />
        <Divider space='2' />
        <Download content={content['download-3']} />
      </Container>
      <Divider space='4' />
      <Divider space='4' />
      <div style={{ width: `100%`, height: `600px` }}>
        <ContentMap
          content={{
            lat: 42.0985394,
            lng: -87.9600665,
            zoom: 14
          }}
        />
      </div>
      {/* <Divider space='4' />
      <Divider space='4' />
      <ContentTwo content={content['services']} /> */}
      <Divider space='4' />
      <Divider space='4' />
      {/* <Team content={content['all-physicians']} />
      <Divider space='4' /> */}
      <Footer content={content['footer2']} />
    </Layout>
  )
}

export const query = graphql`
  query pageRandPatientFormsBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/patient-forms", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default PatientForms
